import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import NodeInjector from "./services/Dom/NodeInjector";
import ContextualBranchManager from './services/Dom/ContextualBranchManager';

const DEFAULT_MOUNT_ID = "scolab-switcher-widget";

class Switcher {
  private configuration = {};
  private mountPointId = DEFAULT_MOUNT_ID;

  public configure(configuration: {}) {
    this.configuration = configuration;
    return this;
  }

  public async mount(target?: string) {
    await this.injectInDom(target);
  }

  public unmount() {
    const frame = document.getElementById(this.mountPointId);
    frame?.parentNode?.removeChild(frame);
  }

  private async injectInDom(target?: string) {
    if (target) {
      this.mountPointId = target;
    } else {
      this.mountPointId = DEFAULT_MOUNT_ID;
      await NodeInjector.div(this.mountPointId);
    }

    const root = createRoot(document.getElementById(this.mountPointId) as HTMLElement);
    root.render(
        <React.StrictMode>
          <App
              unmount={this.unmount.bind(this)}
              configuration={this.configuration}
          />
        </React.StrictMode>,
    );
  }
}

// @ts-ignore
window.ScolabSwitcher = new Switcher();
// @ts-ignore
window.ScolabNodeInjector = NodeInjector;
// @ts-ignore
window.ScolabContextualBranchManager = ContextualBranchManager
