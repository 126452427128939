export default class NodeInjector {
    public static script(filename: string) {
        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", filename);
        script.setAttribute("charset", "utf-8");

        return new Promise((resolve) => {
            document.getElementsByTagName("head")[0].appendChild(script);
            script.onload = resolve;
        });
    }

    public static style(filename: string) {
        const styles = document.createElement("link");
        styles.rel = "stylesheet";
        styles.href = filename;
        styles.media = "all";

        return new Promise((resolve) => {
            document.getElementsByTagName('head')[0].appendChild(styles);
            styles.onload = resolve;
        });
    }

    public static div(id: string) {
        const box = document.createElement("div");
        box.id = id;

        return new Promise<void>((resolve) => {
            document.body.appendChild(box);
            resolve();
        });
    }
}
