import { PackageDetails, S3Client } from './S3Client';

export class PackageTree {
  private readonly branches = new Map<string, PackageDetails>();
  private readonly versions = new Map<string, PackageDetails[]>();
  private readonly s3Client: S3Client;
  private wasFetched = false;

  constructor(s3Client: S3Client) {
    this.s3Client = s3Client;
  }

  public async fetchTreeInformation(cb: () => void) {
    if (!this.wasFetched) {
      const branches = await this.s3Client.getBranches();
      for (const branch of branches) {
        await this.fetchVersionTreeInformation(branch);
      }
      this.wasFetched = true;
      cb();
    } else {
      cb();
    }
  }

  public async fetchVersionTreeInformation(branch: PackageDetails) {
    if (!this.versions.has(branch.folder)) {
      const versions = await this.s3Client.getVersions(branch);
      this.setVersions(branch, versions);
    }
  }

  public getBranches() {
    return Array.from(this.branches.values())
        .sort((a, b) => b.timestamp!.getTime() - a.timestamp!.getTime());
  }

  public getBranchByFolder(folder: string) {
    return this.branches.get(folder);
  }

  public setVersions(branch: PackageDetails, versions: PackageDetails[]) {
    this.versions.set(branch.folder, versions);

    this.branches.set(branch.folder, {
      folder: branch.folder,
      label: branch.label,
      timestamp: versions[0].timestamp,
      dateLabel: versions[0].dateLabel,
    });
  }

  public getVersionsByBranch(branch: PackageDetails): PackageDetails[] | undefined {
    const versions = this.versions.get(branch.folder);
    if (versions) {
      return versions.sort((a, b) => b.timestamp!.getTime() - a.timestamp!.getTime());
    }
  }

  public getLatestVersionByBranch(branch: PackageDetails) {
    const versions = this.getVersionsByBranch(branch);
    return versions?.[0];
  }

  public getVersionByBranch(branch: PackageDetails, versionFolder: string) {
    const versions = this.getVersionsByBranch(branch);
    return versions?.find(v => v.folder === versionFolder);
  }
}
