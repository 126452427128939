import Encoding from "../Typing/Encoding";

export default class ContextualBranchManager {
    public static getSelectedBranch(key: string) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(key)) {
            const urlValue = `${urlParams.get(key)}`;
            return urlValue === 'master__latest' ? urlValue : Encoding.encode(urlValue);
        }

        return window.localStorage.getItem(`env-switcher.${key}.branch`);
    }

    public static getSelectedVersion(key: string) {
        const urlParams = new URLSearchParams(window.location.search);
        const versionKey = `${key}Version`;
        if (urlParams.has(versionKey)) {
            return `${urlParams.get(versionKey)}`;
        }

        return window.localStorage.getItem(`env-switcher.${key}.version`);
    }

    public static setSelectedBranch(encodedBranch: string, key: string) {
        return window.localStorage.setItem(`env-switcher.${key}.branch`, encodedBranch);
    }

    public static setSelectedVersion(version: string, key: string) {
        return window.localStorage.setItem(`env-switcher.${key}.version`, version);
    }
}

