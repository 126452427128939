import React, { Component } from 'react';
import { IProps, IState } from '../S3Selector/S3Selector.models';
import { S3Client, latestPackage, PackageDetails, packageFactory } from '../../services/Clients/S3Client';
import ContextualBranchManager from '../../services/Dom/ContextualBranchManager';
import S3DialogSelector from '../S3DialogSelector/S3DialogSelector';
import ClickLabel from '../Ui/ClickLabel';
import { PackageTree } from '../../services/Clients/PackageTree';
import Dialog from '../Ui/Dialog';

export default class S3Selector extends Component<IProps, IState> {
  private wasMounted = false;

  constructor(props: IProps) {
    super(props);

    const { bucket, subfolder = '' } = props;
    this.state = {
      packageTree: new PackageTree(new S3Client(bucket, subfolder)),
      currentBranch: null,
      isOpenedDialog: false,
    }
  }

  public async componentDidMount() {
    if (!this.wasMounted) {
      this.wasMounted = true;
      await this.validateContextualSelections();
    }
  }

  public render() {
    const { label, onDetails, dialogIsOpened } = this.props;
    const { currentBranch } = this.state;

    const branchLabel = currentBranch ? currentBranch.label : this.getContextualBranchId();
    const versionLabel = this.getContextualVersionId();
    return (
        <>
          {dialogIsOpened && this.renderDialog()}
          <ClickLabel
              label={label}
              title={`${branchLabel} @ ${versionLabel}`}
              value={<span>{branchLabel} <i>@</i> {versionLabel}</span>}
              onClick={() => onDetails()}
          />
        </>
    );
  }

  private renderDialog() {
    const { label, onDetails, defaultSelection } = this.props;
    const { packageTree, currentBranch } = this.state;
    const dialogRef = React.createRef<S3DialogSelector>();

    const onCancel = () => onDetails();
    const onReset = () => {
      this.applyBranchSelectionChange(packageFactory(defaultSelection), latestPackage());
    };
    const onSubmit = () => {
      const data = dialogRef.current?.getData();
      if (data && data.version && data.branch) {
        this.applyBranchSelectionChange(data.branch, data.version);
      }
    };

    return (
        <Dialog onCancel={onCancel} onSubmit={onSubmit} onReset={onReset}>
          <S3DialogSelector
              ref={dialogRef}
              packageTree={packageTree}
              label={label}
              currentBranch={currentBranch?.folder!}
              currentVersion={this.getContextualVersionId()}
          />
        </Dialog>
    );
  }

  private persistBranchSelection(branch: PackageDetails, version: PackageDetails) {
    const { label } = this.props;
    ContextualBranchManager.setSelectedBranch(branch.folder, label);
    ContextualBranchManager.setSelectedVersion(version.folder, label);
  }

  // Applies a branch and version change by reloading the page with the new values.
  private applyBranchSelectionChange(branch: PackageDetails, version: PackageDetails) {
    this.persistBranchSelection(branch, version);
    window.location.reload();
  }

  private redirectToDefaultPackage() {
    // The cookie may have an old value in store and the branch doesn't exist anymore
    // so select the default branch.
    this.applyBranchSelectionChange(packageFactory(this.props.defaultSelection), latestPackage());
  }

  private redirectToDefaultVersion() {
    // The version cookie may not have  been set and we should select one.
    this.applyBranchSelectionChange(packageFactory(this.getContextualBranchId()), latestPackage());
  }

  // Validates the values of what's in the local storage. If any of that fails, it will redirect
  // to the default package and latest version.
  private async validateContextualSelections() {
    const { packageTree } = this.state;
    const { label } = this.props;
    const projectedVersion = this.getContextualVersionId();
    const projectedBranch = this.getContextualBranchId();

    if (!projectedBranch) {
      console.log(`[ENV] ${label}: No branch specified, redirecting to default branch.`);
      return this.redirectToDefaultPackage();
    }

    if (!projectedVersion) {
      console.log(`[ENV] ${label}: No version of ${projectedBranch} was specified, redirecting to latest version.`);
      return this.redirectToDefaultVersion();
    }
    const requiresLatest = projectedVersion === latestPackage().folder;
    if (!requiresLatest) {
      // We are about to load the whole S3 bucket. We should allow consumers to
      // inject their documents right now while we are loading the tree. Worst case
      // scenario is that they will get 404s and we will reload the page after validating.
      console.log(`[ENV] ${label}: Injecting from what we know...`);
      this.notifyOfArtifactSelection(projectedBranch, projectedVersion);
    }

    await packageTree.fetchTreeInformation(async () => {
      // When the projected version is set to latest, we need to fetch the latest versions.
      requiresLatest
        ? await this.validateLatestPackageSelection()
        : await this.validateCachedSelection(projectedBranch, projectedVersion);
    });
  }

  private async validateLatestPackageSelection() {
    const { packageTree } = this.state;
    const { label } = this.props;
    const projectedBranch = this.getContextualBranchId();

    console.log(`[ENV] ${label}: Grabbing versions for ${projectedBranch}...`);

    const branch = packageTree.getBranchByFolder(projectedBranch);
    if (branch) {
      const version = packageTree.getLatestVersionByBranch(branch);
      if (version) {
        // Having a successful validation of the branch and versions means all is good.
        this.setState({ currentBranch: branch });
        this.notifyOfArtifactSelection(branch.folder, version.folder);
        console.log(`[ENV] ${label}: Done.`);
        return;
      }
    }

    console.log(`[ENV] ${label}: Couldn't find a version for ${projectedBranch}, defaulting to default selection at latest package.`);
    return this.redirectToDefaultPackage();
  }

  private async validateCachedSelection(projectedBranch: string, projectedVersion: string) {
    const { packageTree } = this.state;
    const { label } = this.props;
    console.log(`[ENV] ${label}: Testing for ${projectedBranch}@${projectedVersion}...`);

    const branch = packageTree.getBranchByFolder(projectedBranch);
    if (branch) {
      const version = packageTree.getVersionByBranch(branch, projectedVersion);
      if (version) {
        // Having a successful validation of the branch and versions means all is good.
        this.setState({ currentBranch: branch });
        console.log(`[ENV] ${label}: Done.`);
        return;
      }
    }

    console.log(`[ENV] ${label}: Couldn't validate desired version on ${projectedVersion}, defaulting to default selection at latest package.`);
    return this.redirectToDefaultPackage();
  }

  private getContextualBranchId() {
    return ContextualBranchManager.getSelectedBranch(this.props.label) || this.props.defaultSelection;
  }

  private getContextualVersionId() {
    return ContextualBranchManager.getSelectedVersion(this.props.label) ?? 'latest';
  }

  private notifyOfArtifactSelection = (projectedBranch: string, projectedVersion: string) => {
    const { onArtifactSelect } = this.props;
    if (onArtifactSelect) {
      onArtifactSelect(projectedBranch, projectedVersion);
    }
  }
}
