import React, { PropsWithChildren } from 'react';
import { IProps } from './Dialog.models';
import './Dialog.css';

export default class Dialog extends React.Component<PropsWithChildren<IProps>> {
  public render() {
    return (
        <div data-group="scolab-switcher" id="scolab-switcher-dialog">
          <div className="scolab-switcher-dialog-content">
            {this.props.children}
          </div>
          <div className="scolab-switcher-dialog-footer">
            <button type="button" className="scolab-switcher-button" onClick={() => this.props.onReset()}>Reset</button>
            <button type="button" className="scolab-switcher-button" onClick={() => this.props.onCancel()}>Cancel</button>
            <button type="button" className="scolab-switcher-button scolab-switcher-button-primary"
                    onClick={() => this.props.onSubmit()}>Apply
            </button>
          </div>
        </div>
    );
  }
}
