// We aren't using the SDK on purpose because it would require us to
// use an accesskey in production (all API calls need to be authenticated).

import Encoding from "../Typing/Encoding";
import DateParser from "../Typing/DateParser";
import { toBranchLabel } from '../../utils/branches';

export interface PackageDetails {
  readonly timestamp?: Date;
  readonly dateLabel?: string;
  readonly label: string;
  readonly folder: string;
}

export const packageFactory = (id: string) => {
  return {
    folder: id,
    label: toBranchLabel(id),
  } as PackageDetails;
}

export const latestPackage = () => {
  return {
    folder: 'latest',
    label: 'latest',
    timestamp: new Date(),
    dateLabel: "Synced with changes",
  } as PackageDetails;
}

export class S3Client {
  private readonly bucket: string;
  private readonly subfolder: string;

  constructor(bucket: string, subfolder: string) {
    this.bucket = bucket;
    this.subfolder = subfolder;
  }

  public async getBranches() {
    const prefix = this.subfolder ? `&prefix=${this.subfolder}%2F` : '';
    const xml = await this.query(`?list-type=2&delimiter=%2F${prefix}`);
    const goodBranches: PackageDetails[] = []
    const branchXmls = this.convertObjectGroupList(xml);
    for (const branchXml of branchXmls) {
      goodBranches.push({
        folder: branchXml.key,
        label: toBranchLabel(Encoding.decode(branchXml.key)),
        dateLabel: '',
        timestamp: new Date(),
      });
    }
    return goodBranches;
  }

  public async getVersions(packageDetails: PackageDetails) {
    const prefix = this.subfolder ? `${this.subfolder}%2F` : '';
    const xml = await this.query(`?list-type=2&delimiter=%2F&prefix=${prefix}${packageDetails.folder}%2F`);
    const goodVersions: PackageDetails[] = [];

    this.convertObjectGroupList(xml)
        .filter((v) => !v.key.match(/src/) && !v.key.match(/types/))
        .forEach(b => {
          const label = b.key
              .replace(new RegExp(`^${prefix}/`), '')
              .replace(new RegExp(`${packageDetails.folder}/`), '');

          const timestamp = this.getDateFromLabel(label);
          goodVersions.push({
            label,
            timestamp,
            folder: label,
            dateLabel: DateParser.format(timestamp),
          });
        });

    return goodVersions.sort((a, b) => b.timestamp!.getTime() - a.timestamp!.getTime());
  }

  public async validateSelection(branch: string, version: string): Promise<any> {
    const prefix = this.subfolder ? `${this.subfolder}%2F` : '';
    const xml = await this.query(`?list-type=2&delimiter=%2F&prefix=${prefix}${branch}%2F${version}%2F&max-keys=1`);

    const files = this.convertListBucketResult(xml);
    if (!files.length || files[0].keyCount < 1) {
      return null;
    }

    const timestamp = this.getDateFromLabel(version);
    let label = files[0].key
        .replace(new RegExp(`^${prefix}/`), '')
        .replace(new RegExp(`/${version}$`), '');

    label = toBranchLabel(Encoding.decode(label));
    return {
      label,
      timestamp,
      folder: files[0].key,
      dateLabel: DateParser.format(timestamp),
    };
  }

  private getDateFromLabel(version: string) {
    let timeUnit = parseInt(version, 10);
    if (timeUnit < 1000000000000) { // if it's unix seconds instead of js milliseconds
      timeUnit *= 1000;
    }
    return new Date(timeUnit);
  }

  private convertObjectGroupList(xml: Document) {
    return Array
        .from(xml.getElementsByTagName('CommonPrefixes'))
        .map((el: any) => ({
          key: el.getElementsByTagName("Prefix")[0].innerHTML
              .replace(/\/$/, '')
              .replace(new RegExp(`^${this.subfolder}/`), ''),
        }));
  }

  private convertListBucketResult(xml: Document) {
    return Array
        .from(xml.getElementsByTagName('ListBucketResult'))
        .map((el: any) => ({
          key: el.getElementsByTagName("Prefix")[0].innerHTML
              .replace(/\/$/, '')
              .replace(new RegExp(`^${this.subfolder}/`), ''),
          keyCount: parseInt(el.getElementsByTagName("KeyCount")[0].innerHTML, 10),
          lastModified: el.getElementsByTagName("LastModified")[0]?.innerHTML,
        }));
  }

  private async query(parameters: string): Promise<Document> {
    const response = await fetch(`https://${this.bucket}.s3.amazonaws.com/${parameters}`);
    const text = await response.text();
    return (new DOMParser()).parseFromString(text, "text/xml");
  }
}
