import React, { Component } from 'react';
import './ClickLabel.css';
import { IProps } from './ClickLabel.models';

export default class ClickLabel extends Component<IProps> {
  public render() {
    const {
      label,
      value,
      title,
      onClick
    } = this.props;

    return (
        <div className="env-switcher-label" onClick={this.onClick}>
          <div>
            <label>{label}</label>
            <button type="button" title={title}>{value}</button>
          </div>
          {onClick && (<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.98077 11.8094L2.04805 5.87672C1.90534 5.73401 1.83398 5.56071 1.83398 5.35684C1.83398 5.15297 1.90534 4.97967 2.04805 4.83696L2.72083 4.16418C2.86355 4.02147 3.03684 3.95011 3.24071 3.95011C3.44459 3.92972 3.61788 3.99089 3.76059 4.1336L8.50065 8.87366L13.2407 4.1336C13.3834 3.99089 13.5567 3.92972 13.7606 3.95011C13.9645 3.95011 14.1378 4.02147 14.2805 4.16418L14.9533 4.83696C15.096 4.97967 15.1673 5.15297 15.1673 5.35684C15.1673 5.56071 15.096 5.73401 14.9533 5.87672L9.02053 11.8094C8.87782 11.9725 8.70452 12.0541 8.50065 12.0541C8.29678 12.0541 8.12349 11.9725 7.98077 11.8094Z"
                    fill="white"/>
              </svg>
          )}
        </div>
    );
  }

  private onClick = (event: React.MouseEvent) => {
    const {
      onClick,
    } = this.props;

    event.preventDefault();

    if (onClick) {
      onClick(event);
    }
  }
}
