import React, { Component } from 'react';
import { IProps } from './EnvironmentName.models';
import ClickLabel from '../Ui/ClickLabel';

export default class EnvironmentName extends Component<IProps> {
  public render() {
    return (
        <ClickLabel
            label="Environment"
            title={this.props.value}
            value={this.props.value}
        />
    );
  }
}
