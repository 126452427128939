import './App.css';
import React, { Component } from 'react';
import { IProps, IState } from './App.models';
import S3Selector from '../S3Selector/S3Selector';
import NodeInjector from '../../services/Dom/NodeInjector';
import EnvironmentName from "../EnvironmentName/EnvironmentName";

const DEFAULT_SCRIPT_ID = "scolab-switcher";

class App extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            openedDialog: null,
        };
    }

    public async componentDidMount() {
        await NodeInjector.style(`${App.getContextRootUrl()}/main.css`);
    }

    public render() {
        return (
            <div data-group="scolab-switcher" id="scolab-switcher-app">
                {this.getEnvironmentName()}
                {this.getS3Selector()}
                <button type="button" className="scolab-switcher-button" tabIndex={-1} onClick={this.props.unmount}>X</button>
            </div>
        );
    }

    private getEnvironmentName() {
        const { configuration} = this.props;

        if ("environmentName" in configuration) {
            return (
                <EnvironmentName
                    value={configuration["environmentName"]}
                />
            );
        }

        return null;
    }

    private getS3Selector() {
        const {configuration} = this.props;

        if ("s3" in configuration) {
            return Array.from(configuration["s3"]).map((config: any) => {
                return (<S3Selector
                    key={`selector-${config["key"]}`}
                    label={config["key"]}
                    bucket={config["bucket"]}
                    onDetails={() => this.onDetails(`selector-${config["key"]}`)}
                    dialogIsOpened={this.state.openedDialog === `selector-${config["key"]}`}
                    subfolder={config["subfolder"]}
                    defaultSelection={config["defaultSelection"]}
                    useDetailedView={config["skipDetails"] !== true}
                    onArtifactSelect={config["onArtifactSelect"]}
                />);
            });
        }

        return null;
    }

    private onDetails = (dialogKey: string) => {
        this.setState({ openedDialog: this.state.openedDialog === dialogKey ? null : dialogKey });
    };

    private static getContextRootUrl() {
        // This can be uncommented for local development, but it causes weird
        // things to happen in prod.
        if (window.location.href.includes('localhost')) {
            if (window.location.href.indexOf('/?') > 0) {
                return window.location.href.substring(0, window.location.href.indexOf('/?'));
            }

            return window.location.href;
        }

        const script = document.getElementById(DEFAULT_SCRIPT_ID) as HTMLScriptElement;
        if (!script) {
            throw new Error(`Please use the id '${DEFAULT_SCRIPT_ID}' on the script tag including this file.`);
        }

        return script.src.substr(0, script.src.indexOf("/main.js"));
    }
}

export default App;
