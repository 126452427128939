import 'moment-timezone';
import moment from 'moment';

export default class DateParser {
    public static format(date: Date) {
        return moment(date).tz('America/New_York').fromNow();
    }
    public static formatString(date: string) {
        return moment(date).tz('America/New_York').fromNow();
    }
}
