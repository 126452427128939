import React, { Component } from 'react';
import { IProps, IState } from '../S3DialogSelector/S3DialogSelector.models';
import { latestPackage, PackageDetails } from '../../services/Clients/S3Client';
import ClickLabel from '../Ui/ClickLabel';
import './S3DialogSelector.css';

export default class S3DialogSelector extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedVersion: null,
      selectedBranch: null,
      isLoaded: false,
      branchesOpen: false,
      versionsOpen: false,
    }
  }

  public async componentDidMount() {
    const { packageTree, currentVersion, currentBranch } = this.props;
    await packageTree.fetchTreeInformation(() => {
      const selectedBranch = packageTree.getBranchByFolder(currentBranch)!;
      const selectedVersion = packageTree.getVersionByBranch(selectedBranch, currentVersion);
      this.setState({
        selectedVersion: selectedVersion ?? latestPackage(),
        selectedBranch,
        isLoaded: true,
      });
    });
  }

  public render() {
    const { selectedBranch, selectedVersion, branchesOpen, versionsOpen, isLoaded } = this.state;

    if (!isLoaded) {
      return (
          <div className="scolab-switcher-s3dialogselector">
            <p><i>Loading...</i></p>
          </div>
      );
    }

    return (
        <div className="scolab-switcher-s3dialogselector">
          {branchesOpen && (
              <div className="scolab-switcher-s3dialogselector-popup scolab-switcher-s3dialogselector-popup-branches">
                {this.getBranchOptions()}
              </div>)}
          {versionsOpen && (
              <div className="scolab-switcher-s3dialogselector-popup scolab-switcher-s3dialogselector-popup-versions">
                {this.getBranchVersionOptions()}
              </div>)}
          <ClickLabel label="Branch"
                      title={selectedBranch!.label}
                      value={selectedBranch!.label}
                      onClick={() => this.setState({ branchesOpen: !branchesOpen, versionsOpen: false })}/>
          <ClickLabel label="Version"
                      title={`${selectedVersion?.label}`}
                      value={selectedVersion?.label}
                      onClick={() => this.setState({ versionsOpen: !versionsOpen, branchesOpen: false })}/>
        </div>
    );
  }

  public getData() {
    return {
      branch: this.state.selectedBranch,
      version: this.state.selectedVersion,
    };
  }

  private onBranchChange(selectedBranch: string) {
    const { packageTree } = this.props;
    const previousBranch = this.state.selectedBranch;
    if (previousBranch?.folder !== selectedBranch)  {
      this.setState({
        selectedBranch: packageTree.getBranchByFolder(selectedBranch)!,
        selectedVersion: latestPackage(),
      });
    }
  }

  private onVersionChange(version: string) {
    const { packageTree } = this.props;
    const { selectedBranch } = this.state;
    const latest = latestPackage();

    this.setState({
      selectedVersion: version === latest.folder
          ? latest
          : packageTree.getVersionByBranch(selectedBranch!, version)!,
    });
  }

  private getBranchVersionOptions() {
    const { selectedBranch } = this.state;
    const { packageTree } = this.props;
    const versions = packageTree.getVersionsByBranch(selectedBranch!);
    if (versions) {
      const versionsWithLatest = [latestPackage()].concat(versions);
      return versionsWithLatest.map((p, index) => this.makeVersionOption(p, index));
    }
  }

  private getBranchOptions(): React.ReactNode {
    const { packageTree } = this.props;
    return packageTree.getBranches().map(this.makeBranchOption);
  }

  private makeBranchOption = (p: PackageDetails) => {
    const { selectedBranch } = this.state;
    const isActive = selectedBranch!.folder === p.folder;
    const check = isActive ? " ✓" : null;
    return (
        <button
            type="button"
            className={`scolab-switcher-s3dialogselector-choice ${isActive ? 'active' : ''}`}
            key={p.folder}
            value={p.folder}
            onClick={() => this.onBranchChange(p.folder)}
        >
          <strong>{p.label}{check}</strong>
          <time>{p.dateLabel}</time>
        </button>
    );
  }

  private makeVersionOption(p: PackageDetails, index: number) {
    const { selectedVersion } = this.state;
    const selectedFolder = selectedVersion ? selectedVersion.folder : null;
    const isSelected = p.folder === selectedFolder;
    const isMostRecentChoice = selectedFolder === null && index === 0;
    const isActive = isSelected || isMostRecentChoice;
    const check = isActive ? " ✓" : null;

    return (
        <button
            type="button"
            className={`scolab-switcher-s3dialogselector-choice ${isActive ? 'active' : ''}`}
            key={p.folder}
            value={p.folder}
            onClick={() => this.onVersionChange(p.folder)}
        >
          <strong>{p.label}{check}</strong>
          <time>{p.dateLabel}</time>
        </button>
    );
  }

}
