export default class Encoding {
    public static encode(text: string) {
        try {
            return window.btoa(text);
        } catch (e) {
            return '';
        }
    }

    public static decode(text: string) {
        try {
            const base64 = text.replace('_', '/');
            return window.atob(base64);
        } catch (e) {
            // Not b64 -> Backward compat
            return text;
        }
    }
}
